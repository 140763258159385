import React from 'react';
import * as tConst from "../../common/Constants";
import { Button, InputNumber, Select, Input } from 'antd';



const ChartMenuForm = ({selectedTicket, fromDate, toDate, assignedTo, initiaitve, jiraLink, onUpdateTicket, onSetRefresh}) => {
    const [planningPriority, setPlanningPriority] = React.useState(selectedTicket.fields[tConst.AF_PLANNING_PRIORITY]);
    const [planningStatus, setPlanningStatus] = React.useState(selectedTicket.fields[tConst.AF_PLANNING_STATUS]);
    const [initiative, setInitiative] = React.useState(selectedTicket.fields[tConst.AF_INITIATIV]);



    const { Option } = Select;

    const _changed = (planningPriority !== selectedTicket.fields[tConst.AF_PLANNING_PRIORITY] 
        || planningStatus !== selectedTicket.fields[tConst.AF_PLANNING_STATUS]
        || initiative !== selectedTicket.fields[tConst.AF_INITIATIV]);

    return (
        <>
                Period: &nbsp;
                {fromDate ? tConst.formatDate(fromDate) : ""} -{" "}
                {toDate ? tConst.formatDate(toDate) : ""} <br />
                {/* ToDo: Make assignee editiable */}
                Assignee: {assignedTo ? assignedTo.displayName : tConst.ADO_ASSIGNED_ALL} <br />
                {/* TODO: Rewrite this to be a drop down that can be changed */}
                Status: {selectedTicket.fields[tConst.AF_STATE]} <br />

                {/* Todo: Change this to be a drop downdown with all intiaitive in the list */}
                Planning Status: &nbsp;
                <Select value={planningStatus} onChange={setPlanningStatus} style={{ width: 200 }}>
                    {tConst.ADO_PLANNING_STATUSES.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select><br />
                Initiative:&nbsp;  
                <Input value={initiative} onChange={(e) => setInitiative(e.target.value)} style={{ width: 300 }} />                
                <a href={tConst.AZURE_DEVOPS_BASE_URI +  "_search?type=workitem&text=initiative%3A%22"+ initiaitve+"%22"} target="_blank" rel="noopener noreferrer">Open</a><br/>

                Planning Priority: <InputNumber value={planningPriority} onChange={setPlanningPriority} /> <br />
                {selectedTicket.fields[tConst.AF_JIRA_LINK] && <>Jira Ref: <a href={jiraLink}>{selectedTicket.fields[tConst.AF_JIRA_LINK]}</a> <br/></>}
                <>Teams: {selectedTicket.Teams} <br /></>
                {_changed && <Button type="primary" onClick={() => {
                    if (onUpdateTicket) {
                        let _data = [];
                        if (selectedTicket.fields[tConst.AF_PLANNING_PRIORITY] !== planningPriority) {
                            _data.push({attribute: "/fields/" + tConst.AF_PLANNING_PRIORITY, "value": planningPriority });
                        }
                        if (selectedTicket.fields[tConst.AF_PLANNING_STATUS] !== planningStatus) {
                            _data.push({attribute: "/fields/" + tConst.AF_PLANNING_STATUS, "value": planningStatus });
                        }
                        if (selectedTicket.fields[tConst.AF_INITIATIV] !== initiative) {
                            _data.push({attribute: "/fields/" + tConst.AF_INITIATIV, "value": initiative });
                        }
                        onUpdateTicket(selectedTicket.id, _data).then((response)=> {
                            onSetRefresh();
                        });
                    }
                }}
                >
                    Save
                {/* TODO: Fix refresh on save */}
                </Button> }{_changed &&<br/>} 
        </>
    );
};

export default ChartMenuForm;