import React, { useState } /*, { useState, useEffect }*/ from "react";
import Chart from "react-google-charts";
import * as tConst from "../../common/Constants";
import * as theader from "../timelineHeader";
import { PrepareTimelineDataAdo } from "./PrepareTimelineDataAdo";
import ChartMenuAdo from "./ChartMenu_Ado";
import ChartSprintHeaderMenuAdo from "./ChartSprintHeaderMenu_Ado";
import * as iterationTool from "../../utils/getIterationTime"

const arraySetup = [
  [
    { type: "string", id: "Term" },
    { type: "string", id: "Name" },
    { type: "string", role: "tooltip", p: { html: true } },
    { type: "string", id: "style", role: "style" },
    { type: "date", id: "Start" },
    { type: "date", id: "End" },
  ],
];


const TimelineAdo = ({
    items, 
    viewId,
    assigneeFilterName,
    selectedInitiative,
    teamsFilterName,
    openOnClickStatus, 
    onMoveAndExtendIteration, 
    showChartMenu, 
    setShowChartMenu, 
    onAddTicketToSprint,
    onSetRefresh,
    Search,
    auth,
    onUpdateTicket
  }) => {
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [menuTitle, setMenuTitle] = useState("");
  const [menuTicketID, setMenuTicketID] = useState("");
  const [selectedSprint, setSelectedSprint] = useState();
  const [showSprintHeaderChartMenu, setShowSprintHeaderChartMenu] = useState(false);
  let dataArray = [];
//  let _minDate = new Date(2023, 11, 21, tConst.START_TIME);
//  let _maxDate = new Date(2024, 3, 23, tConst.END_TIME);
  
  dataArray = dataArray.concat(arraySetup);


  const {dataset, minDate, maxDate} = PrepareTimelineDataAdo(items, viewId, assigneeFilterName, teamsFilterName, selectedInitiative, auth);

  const _iterationHeader = theader.iterationHeader(minDate, maxDate);
  dataArray = dataArray.concat(theader.createQuarterHeader(_iterationHeader));
  dataArray = dataArray.concat(_iterationHeader);
  dataArray = dataArray.concat(dataset);
  
  if (items?.length > 0){
    const _enableHalfIterations = selectedTicket ? ((selectedTicket.fields[tConst.AF_PLANNING_STATUS] === tConst.PLANNING_STATUS_NA) || (selectedTicket.project === "OPS" )) : false
  return (
    <>
      <ChartMenuAdo 
        showChartMenu={showChartMenu} 
        selectedTicket={selectedTicket} 
        menuTitle={menuTitle} 
        menuTicketID={menuTicketID}
        enableHalfIterations={_enableHalfIterations}
        auth={auth}
        handleClose={ () => {setShowChartMenu(false);}}
        onMoveAndExtendIteration={onMoveAndExtendIteration}
        onSetRefresh={onSetRefresh}
        onUpdateTicket={onUpdateTicket}
        />

      <ChartSprintHeaderMenuAdo 
          showSprintHeaderChartMenu={showSprintHeaderChartMenu && !showChartMenu} 
          handleClose={ () => {setShowSprintHeaderChartMenu(false); }}
          onAddTicketToSprint={onAddTicketToSprint}
          selectedSprint = {selectedSprint}
          setSelectedSprint = {setSelectedSprint}
          Search={Search}
          onSetRefresh={onSetRefresh}
      />

        <Chart
          width={"99%"}
          height={"90vh"}
          chartType="Timeline"
          options={{ height: 1500, avoidOverlappingGridLines: true }}
          data={dataArray}
          chartEvents={[
            {
              eventName: "select",
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart();
                const selection = chart.getSelection();
                if (selection.length === 1) {
                  const [selectedItem] = selection;
                  const dataTable = chartWrapper.getDataTable();
                  const { row } = selectedItem;
                  const title = dataTable.getValue(row, 1);
                  const ticketId = title.substring(title.indexOf("[") + 1, title.indexOf("]"));
                  const regexp = new RegExp("^[2][0-9]T[0-9]{1,2}$");
                  const sprintId = regexp.exec(title);
                  if (ticketId !== "") {
                    if (openOnClickStatus) {
                      const url = tConst.AZURE_DEVOPS_BASE_TICKET_URI  + (ticketId ? ticketId : "");
                      window.open(url, "_blank");
                    } else {
                      const _selectedTicket = items.filter((item) => {
                        return item.id.toString() === ticketId}); 
                        
                      setSelectedTicket(_selectedTicket[0]);
                      setMenuTitle(title)
                      setMenuTicketID(ticketId);
                      setShowSprintHeaderChartMenu(false);
                      setShowChartMenu(true);
                    }
                  }
                  else if (sprintId!== ""){
                    const ToolTip = dataTable.getValue(row, 2)
                    const period = new RegExp("Period: [0-9]{1,2}\\. \\w{3} [0-9]{4} - [0-9]{1,2}\\. \\w{3} [0-9]{4}").exec(ToolTip);
                    const sDate = new RegExp("[0-9]{1,2}\\. \\w{3} [0-9]{4}").exec(period);
                    const eDate = new RegExp("[0-9]{1,2}\\. \\w{3} [0-9]{4}$").exec(period);
                    const _sDate = iterationTool.getIterationStart(new Date(sDate));
                    const _eDate = iterationTool.getIterationEnd(new Date(eDate));
                    setSelectedSprint({SprintName: sprintId, StartDate: _sDate, EndDate: _eDate });
                    setShowChartMenu(false);
                    setShowSprintHeaderChartMenu(true);
                  } 
                }
              },
            },
          ]}
        />
      </>
    )
  }
  else {
    return(
      <>
        <br/>
        <button className="btn btn-primary">Add ticket to timeling</button>
      </>
    )
  }

}

export default TimelineAdo;



  //#region iterationHeader 


  //#endregion iterationHeader

  /*
class TimelineAdo extends Component {
  state = {
    menuTitle: "",
    menuTicketID: "",
  };
  

  render() {
    let dataArray = [];
    dataArray = dataArray.concat(arraySetup);

    const iterationHeader = this.iterationHeader(this.props.minDate, this.props.maxDate);
    dataArray = dataArray.concat(this.createQuarterHeader(iterationHeader));

    dataArray = dataArray.concat(iterationHeader);
    dataArray = dataArray.concat(this.props.timelineData);

    if (!this.props.isLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.minDate >= this.props.maxDate) {
      return <div>No data to present</div>;
    } else {
      return (
        <>
          <ChartMenu
            showChartMenu={this.props.showChartMenu}
            selectedTicket={this.props.selectedTicket}
            title={this.state.menuTitle}
            menuTicketID={this.state.menuTicketID}
            fromDate={null}
            toDate={null}
            onMoveIteration={this.props.onMoveIteration}
            onExtendIteration={this.props.onExtendIteration}
            updatePlanningPriority = {this.props.updatePlanningPriority}
            assigned={this.props.selectedTicket ? this.props.selectedTicket.assigned : null}
            tickets={this.props.tickets}
            demo={this.props.demo}
            updateDemo={this.props.updateDemo}
            enableHalfIterations={
              this.props.selectedTicket
                ? ((this.props.selectedTicket.planningStatus === tConst.PLANNING_STATUS_NA) || (this.props.selectedTicket.project === "OPS" ))
                : false
            }
            handleClose={() => {
              this.props.onShowChartMenu(false);
            }}
          ></ChartMenu>
      
          <Chart
            width={"99%"}
            height={"70%"}
            chartType="Timeline"
            options={{ height: 1500, avoidOverlappingGridLines: true }}
            data={dataArray}
            chartEvents={[
              {
                eventName: "select",
                callback: ({ chartWrapper }) => {
                  const chart = chartWrapper.getChart();
                  const selection = chart.getSelection();
                  if (selection.length === 1) {
                    const [selectedItem] = selection;
                    const dataTable = chartWrapper.getDataTable();
                    const { row } = selectedItem;
                    const title = dataTable.getValue(row, 1);
                    const ticketId = title.substring(title.indexOf("[") + 1, title.indexOf("]"));
                    if (ticketId !== "") {
                      if (this.props.openOnClickStatus) {
                        const url = tConst.JIRA_BASE_URI + "browse/" + ticketId;
                        window.open(url, "_blank");
                      } else {
                        console.log("2020-08-30 - Open Menu", ticketId);
                        this.props.selectTicket(ticketId);
                        this.setState({ menuTitle: title });
                        this.setState({ menuTicketID: ticketId });
                        this.props.onShowChartMenu(true);
                      }
                    }
                  }
                },
              },
            ]}
          />
        </>
      );
    }
  }

  findQuarterNameFromDate(date) {
    return date.getYear().toString() + "Q" + date.getMonth();
  }


    return quarterHeaders;
  };

}
// this.props...}

export default TimelineAdo;
TimelineAdo.propTypes = {
  timelineData: PropTypes.array.isRequired,
  minDate: PropTypes.objectOf(Date).isRequired,
  maxDate: PropTypes.objectOf(Date).isRequired,
};

*/