import { todayIso } from "../utils/date";

export const COLOR_ITERATION = "#00e6e6";
export const COLOR_CURRENT_ITERATION = "#00b0f0";
export const COLOR_QUARTALS = "#0070c0";
export const COLOR_DONE_QA = "#d0f0c0";
export const COLOR_DONE_NA = "#63278f";
export const COLOR_DIM = "#CFCFC4"
export const COLOR_DONE = "#336600";
export const COLOR_DUPLICATE = "#c0f0c8";
export const COLOR_REMOVED = "#800028";
export const COLOR_IMPEDIMENT = "#404040";
export const COLOR_NA = "#954eca";
export const COLOR_READY4CODING = "#00b050";
export const COLOR_SPECING = "#cc0000";
export const COLOR_PLANNING = "#ffcc00";
export const COLOR_OTHER = "#D3D3D3";
export const COLOR_UNKNOWN = "#F81894";
export const START_TIME = 16;
export const END_TIME = 4;


// Views
export const VIEW_ALL_PLANNED = 1;
export const VIEW_LATE = 2;
export const VIEW_ACTIVE = 3;
export const VIEW_ACTIVE_AND_CURRENT = 4;
export const VIEW_CURRENT_ITERATION = 5;
export const VIEW_NEXT_ITERATION = 6;
export const VIEW_FUTURE = 7;
export const VIEW_DEMO = 8;
export const VIEW_MISSING_DATES = 9;
export const VIEW_QA = 10;
export const VIEW_DONE = 11;


//PLANNING_STATUS
export const PLANNING_STATUS_NA = "9. N/A";

export const VIEW_SELECT_OPTION = [
  { value: VIEW_ALL_PLANNED, label: "All planned" },
//  { value: VIEW_LATE, label: "Late" },
  { value: VIEW_ACTIVE, label: "Active" },
  { value: VIEW_ACTIVE_AND_CURRENT, label: "Active & Current" },
  //{ value: VIEW_QA, label: "QA" },
  //{ value: VIEW_DONE, label: "Done" },
  { value: VIEW_CURRENT_ITERATION, label: "Current iteration" },
  //{ value: VIEW_NEXT_ITERATION, label: "Next iterations" },
  { value: VIEW_FUTURE, label: "Future" },
  //{ value: VIEW_DEMO, label: "Demo" },
  //{ value: VIEW_MISSING_DATES, label: "Missing dates" },
];

export const VIEW_ALL_TEAMS = "<All Teams>";
export const VIEW_NO_TEAMS = "<No Teams>";
export const AF_TEAMS = ["Unity", "RoomMate", "Apps", "Infrastructure", "Vista", "Data platform", "Insight", "Test", "Data"];
const VIEW_TEAMS = AF_TEAMS.map((team) => {return{value: team, label: team}});

export const VIEW_SELECT_TEAMS_OPTION = [
  { value: VIEW_ALL_TEAMS, label: "<All Teams>" },
  { value: VIEW_NO_TEAMS, label: "<No Teams>" },
  ...VIEW_TEAMS.sort((a, b) => a.label > b.label ? 1 : -1)
]

//*************************************************************************
/* Azure DevOps Constants
 *************************************************************************/
export const AZURE_DEVOPS_BASE_URI = "https://dev.azure.com/sensio/";
export const AZURE_DEVOPS_API_URI = AZURE_DEVOPS_BASE_URI+"_apis/";
export const AZURE_DEVOPS_BASE_TICKET_URI = AZURE_DEVOPS_BASE_URI+"_workitems/edit/";

export const AZURE_Q_FIELDS = "Select [System.Id] , [System.Title], [System.State], [Custom.EndIteration], [Custom.StartIteration], [Custom.PlanningPriority], [System.AssignedTo], [System.Description] From WorkItems "
export const AZURE_Q1 = AZURE_Q_FIELDS+" WHERE [Custom.EndIteration] <> '' AND [State] <> 'Closed' AND [State] <> 'Removed' "
export const AZURE_Q_ORDER_BY = " order by [Custom.PlanningPriority] asc, [Custom.StartIteration] asc, [System.CreatedDate] asc";
export const AZURE_Q_PROJECT =  " and [System.TeamProject] = " 
export const AZURE_SPECS = ["FuncSpec", "DevPlan", "TestSpec"];
export const AF_INITIATIV = "Custom.Initiative";
export const AF_PLANNING_PRIORITY = "Custom.Planningpriority";
export const AF_START_ITERATION = "Custom.StartIteration";
export const AF_END_ITERATION = "Custom.EndIteration";
export const AF_TITLE = "System.Title";
export const AF_TYPE = "System.WorkItemType";
export const AF_ASSIGNED_TO = "System.AssignedTo";
export const AF_REPORTER = "System.CreatedBy"
export const AF_STATE = "System.State"
export const AF_PARENT = "System.Parent";
export const AF_RELATION = "relations";
export const AF_REL = "rel";
export const AF_REL_PARENT= "System.LinkTypes.Hierarchy-Reverse";
export const AF_REL_CHILD= "System.LinkTypes.Hierarchy-Forward";
export const AF_ID = "id";
export const AF_PROJECT= "System.TeamProject"
export const AF_AREA_PATH = "System.AreaPath";
export const AF_WORKITEM_TYPE = "System.WorkItemType";
export const AF_PLANNING_STATUS = "Custom.PlanningStatus";
export const AF_JIRA_LINK = "Custom.JiraIssueKey";
export const AF_TRIAGE_DATA = "Custom.TriageData"; 
export const AF_TRIAGE_DATES = "Custom.TriageDates";
export const AF_TRIAGE_DECISION = "Custom.TriageDecision";
export const AF_REPRO_STEPS = "Microsoft.VSTS.TCM.ReproSteps";
export const AF_DESCRIPTION = "System.Description";
export const AF_JUSTIFICATION = "Microsoft.VSTS.CMMI.Justification";
export const AF_TAGS = "System.Tags";

export const ADO_ASSIGNED_ALL = "<All>";
export const ADO_UNASSIGNED = "<Unassigned>"
export const ADO_INITIATIVE_ALL = "<All>";
export const ADO_INITIATIVE_BLANK = "<No Initiative>"

export const ADO_INACTIVE_STATUSES=["Done","Closed", "Resolved", "Won't do", "WontDo", "Candidate", "Removed"]
export const ADO_VALID_LINK_TYPES = ["Dependency-Forward", "Dependency-Reverse", "Hierarchy-Forward", "Hierarchy-Reverse", "Related"];

export const ADO_IMPEDIMENT_TYPES=["Impediment"]
export const ADO_PLANNING_STATUSES = [
  { value: '0. Not Started', label: '0. Not Started' },
  { value: '1. FuncSpec in-progress', label: '1. FuncSpec in-progress' },
  { value: '2. FuncSpec Complete', label: '2. FuncSpec Complete' },
  { value: '3. DevPlan in-progress', label: '3. DevPlan in-progress' },
  { value: '4. Ready-for-coding', label: '4. Ready-for-coding' },
  { value: '9. N/A', label: '9. N/A' },
];


export const TRIAGE_TEXT = 
  [
    { triage: "Triage Yes",
      standardTexts: [
        {name: "Yes", value: "Triage: Yes, ready for development"}, 
        {name: "Yes, if small", value: "Triage: Yes, let us fix this if it is small, if not please bring it back for retriage"}
      ]
    },
    { triage: "Triage No",
      standardTexts: [
        {name: "Below the bar", value: "Triage: No, Below the bar"}, 
        {name: "No, not in isolation", value: "Triage: No. We will not take this in isolation, improvements may be included in future versions"}
      ]
    },
    { triage: "Cannot reproduce",
      standardTexts: [
        {name: "Cannot reproduce", value: "Triage: No, Cannot reproduce. The problem report has been analyzed but we are unable to reproduce the issue"}, 
        {name: "No reponse", value: "Triage: No, Cannot reproduce. The problem report has been analyzed, but we are unable to reproduce the issue. Further requests for more information from the bug reporter has been unanswered."}
      ]
    },
    { triage: "Not a Bug",
      standardTexts: [
        {name: "Not a bug", value: "Triage: No, Not a bug. The problem report has been analyzed the issue is not a bug, please consult the product documentation for a detailed description of the feature"}, 
        {name: "Not supported", value: "Triage: No, Not a bug. The problem report has been analyzed the issue is not a bug. The feature is not built to support the reported behavior."}
      ]
    },
    { triage: "Need More Info",
      standardTexts: [
        {name: "NeedMoreInfo", value: "Triage: NeedMoreInfo. The problem report has been analyzed and more information is needed to proceed, pleaes provice <insert details>"},
        {name: "Not an isolated reproducible bug", value: "Triage: NeedMoreInfo. The problem report has been analyzed and is is not an isolated reproducible bug. More information is needed to proceed, please consult https://sensio.atlassian.net/wiki/spaces/TDeng/pages/32143900673/How+to+create+good+bug+reports"}, 
      ]
    },

  ]
  

export const ADO_OPS_PROJECT = "5eba0ff3-bf3e-47a6-b64d-2a6d776622d3";
export const ADO_OPS_TRIAGE_QUERY = AZURE_Q_FIELDS+" WHERE  [System.TeamProject] = 'Operations'" + AZURE_Q_ORDER_BY;

export const ADO_TRIAGE_PROJECTS = [
  "c65e8f3b-c17c-4ddb-b567-4e249c025223", // APPS
  "fb1d55bd-b1ec-4bd6-b747-8d7a809943d9", //frontend
  "abc8e8c9-07d5-4c74-b59f-3b561211598b", // oyvindh
  "897cbb6e-8a8f-412b-8491-09dd2f1daf06", //RoomMate
  "645350c7-676e-4376-b777-78d3cff86d3b", // Unity
  "56d8f542-cd55-45b2-8f55-b762fd3475ff", // Triage
]

const ADO_TRIAGE_FIELDS = "[System.Id], [System.Title], [System.State], [Custom.EndIteration], [Custom.StartIteration], [Custom.PlanningPriority], [System.AssignedTo], [Microsoft.VSTS.TCM.ReproSteps]"
export const ADO_TRIAGE_QUERY = "Select "+ADO_TRIAGE_FIELDS+" From WorkItems WHERE ([State] = 'Ready4Triage' OR [State] = 'Ready for triage' OR ["+AF_TRIAGE_DATES+"] CONTAINS '"+todayIso()+"')"

export const AZURE_PLANNING_QUERY_OPS = {
  label: "Operations Planning", 
  value:"0ec7dc3e-8f50-4f8a-919f-a83c0396c2b7",
  query: AZURE_Q1+ "AND [System.TeamProject] = 'Operations'" + AZURE_Q_ORDER_BY, 
}
export const AZURE_PLANNING_QUERY_TEST_FOR_OYVINDH = {
  label:"Test for oyvindh", 
  value: "{ea2c77fe-589a-4865-9f9f-6a149a649a3c}",
  query: AZURE_Q1+ "AND [System.TeamProject] = 'Test for oyvindh'" + AZURE_Q_ORDER_BY, 
}

export const AZURE_TIMELINE_FILTERS = {
  filterList:[
    AZURE_PLANNING_QUERY_OPS,
    AZURE_PLANNING_QUERY_TEST_FOR_OYVINDH
  ], 
  getFilterById  (filterId) {
    return this.filterList.filter((filt) => {
      return filt.id === filterId;
    })
  },
  getFilterByName  (filterName) {
    return this.filterList.filter((filt) => {
      return filt.name === filterName;
    })
  }

};


//**************************************************************************
/* JIRA constants
**************************************************************************/
//TODO: The Jira instance should be part of the user profile, not the const
export const JIRA_BASE_URI = "https://jira.sensio.no/";
export const JIRA_UPDATE_ISSUE_API = "rest/api/2/issue/";
export const JIRA_PLANNED_QUERY =
  "https://jira.sensio.no/rest/api/2/search?jql=Type+not+in+(Initiative)+AND+%22Start+iteration%22+is+not+EMPTY+AND+(status+not+in+(Resolved,+Closed)+OR+%22End+iteration%22+%3E%3D+-48d)+ORDER+BY+%22Planning+priority%22+ASC,+%22Start+iteration%22+ASC&expand=names&maxResults=500";

export const JIRA_PLANNED_QUERY_VIEW =
  "https://jira.sensio.no/issues/?jql=project%3DUnity%20and%20%22Start%20iteration%22%20is%20not%20EMPTY%20and%20(status%20not%20in%20(Resolved%2C%20Closed)%20or%20%22End%20iteration%22%20%3C%3D%2048d)";

export const JIRA_PLANNING_OPS =
  "https://jira.sensio.no/rest/api/2/search?jql=(project+%3D+Operations+OR+labels+in+(INCLUDE_IN_OPS_PLANNING))+AND+Type+not+in+(Initiative)+AND+%22Start+iteration%22+is+not+EMPTY+AND+(status+not+in+(Resolved,+Closed)+OR+%22End+iteration%22+%3E%3D+-28d)+AND+%22Start+iteration%22+%3C%3D+120d+ORDER+BY+%22Planning+priority%22+ASC,+%22Start+iteration%22+ASC&maxResults=500";

export const JIRA_PLANNING_OPS_VIEW =
  "https://jira.sensio.no/issues/?jql=(project%3DOperations+OR+labels+in+(INCLUDE_IN_OPS_PLANNING))+AND+%22Start%20iteration%22%20is%20not%20EMPTY%20and%20(status%20not%20in%20(Resolved%2C%20Closed)%20or%20%22End%20iteration%22%20%3E%3D%2028d)%20and%20%22Start%20iteration%22%20%3C%3D120d";

  export const JIRA_PLANNING_UNITY =
  "https://jira.sensio.no/rest/api/2/search?jql=project+%3D+Unity+AND+Type+not+in+(Initiative)+AND+%22Start+iteration%22+is+not+EMPTY+AND+(status+not+in+(Resolved,+Closed)+OR+%22End+iteration%22+%3E%3D+-28d)+AND+%22Start+iteration%22+%3C%3D+120d+ORDER+BY+%22Planning+priority%22+ASC,+%22Start+iteration%22+ASC&maxResults=500";

export const JIRA_PLANNING_UNITY_VIEW2 =
  "https://jira.sensio.no/issues/?jql=project%3DUnity+AND+%22Start%20iteration%22%20is%20not%20EMPTY%20and%20(status%20not%20in%20(Resolved%2C%20Closed)%20or%20%22End%20iteration%22%20%3E%3D%2028d)%20and%20%22Start%20iteration%22%20%3C%3D120d";


export const JIRA_PLANNING_IKOS =
  "https://jira.sensio.no/rest/api/2/search?jql=project+%3D+IKOS+AND+Type+not+in+(Initiative)+AND+%22Start+iteration%22+is+not+EMPTY+AND+(status+not+in+(Resolved,+Closed)+OR+%22End+iteration%22+%3E%3D+-28d)+AND+%22Start+iteration%22+%3C%3D+120d+ORDER+BY+%22Planning+priority%22+ASC,+%22Start+iteration%22+ASC&maxResults=500";
export const JIRA_PLANNING_IKOS_VIEW =
  "https://jira.sensio.no/issues/?jql=project%3DIkos%20and%20%22Start%20iteration%22%20is%20not%20EMPTY%20and%20(status%20not%20in%20(Resolved%2C%20Closed)%20or%20%22End%20iteration%22%20%3C%3D%2028d)%20and%20%22Start%20iteration%22%20%3C%3D120d+ORDER+BY+%22Planning+priority%22+ASC,+%22Start+iteration%22+ASC";

export const JIRA_MY_PLANNED =
  "https://jira.sensio.no/rest/api/2/search?jql=assignee+%3D+currentUser()+AND+%22Start+iteration%22+is+not+EMPTY+AND+(status+not+in+(Resolved,+Closed)+OR+%22End+iteration%22+%3E%3D+-28d)+AND+%22Start+iteration%22+%3C%3D+120d+ORDER+BY+%22Planning+priority%22+ASC,+%22Start+iteration%22+ASC&maxResults=500";
export const JIRA_MY_PLANNED_VIEW =
  "https://jira.sensio.no/issues/?jql=assignee%20%3D%20currentUser()";

export const JIRA_ROADMAP =
  "https://jira.sensio.no/rest/api/2/search?jql=Type+in+(Initiative)+ORDER+BY+%22Planning+priority%22+ASC,+%22Start+iteration%22+ASC&expand=names&maxResults=500";

export const JIRA_ROADMAP_VIEW =
  "https://jira.sensio.no/issues/?jql=Type+in+(Initiative)+ORDER+BY+%22Planning+priority%22+ASC,+%22Start+iteration%22+ASC";


  export const JIRA_PLANNING_APPS =
  "https://jira.sensio.no/rest/api/2/search?jql=(project+%3D+Apps+OR+labels+in+(INCLUDE_IN_APPS_PLANNING))+AND+Type+not+in+(Initiative)+AND+%22Start+iteration%22+is+not+EMPTY+AND+(status+not+in+(Resolved,+Closed)+OR+%22End+iteration%22+%3E%3D+-28d)+AND+%22Start+iteration%22+%3C%3D+120d+ORDER+BY+%22Planning+priority%22+ASC,+%22Start+iteration%22+ASC&maxResults=500";

export const JIRA_PLANNING_APPS_VIEW =
  "https://jira.sensio.no/issues/?jql=(project%3DApps+OR+labels+in+(INCLUDE_IN_APPS_PLANNING))+AND+%22Start%20iteration%22%20is%20not%20EMPTY%20and%20(status%20not%20in%20(Resolved%2C%20Closed)%20or%20%22End%20iteration%22%20%3E%3D%2028d)%20and%20%22Start%20iteration%22%20%3C%3D120d";

// prettier-ignore
export const JIRA_DEFAULT_FILTERS = [
  { value: -1, label: "_ Planned", searchUrl: JIRA_PLANNED_QUERY, viewUrl: JIRA_PLANNED_QUERY_VIEW },
  { value: -2, label: "_ Unity planning", searchUrl: JIRA_PLANNING_UNITY, viewUrl:  JIRA_PLANNING_UNITY_VIEW2},
  { value: -3, label: "_ Operations", searchUrl: JIRA_PLANNING_OPS, viewUrl:  JIRA_PLANNING_OPS_VIEW},
  { value: -4, label: "_ IKOS planning", searchUrl: JIRA_PLANNING_IKOS, viewUrl: JIRA_PLANNING_IKOS_VIEW},
  { value: -5, label: "_ Apps", searchUrl: JIRA_PLANNING_APPS, viewUrl:  JIRA_PLANNING_APPS_VIEW},
  { value: -6, label: "_ My planned", searchUrl: JIRA_MY_PLANNED, viewUrl: JIRA_MY_PLANNED_VIEW},
  { value: -7, label: "_ Roadmap", searchUrl: JIRA_ROADMAP, viewUrl: JIRA_ROADMAP_VIEW}
];

export const DEFAULT_EPICS_FILTER = [{ label: "<All Epics>", value: "<All Epics>" }];
export const DEFAULT_INITIATIVE_FILTER = [
  { label: "<All Initiatives>", value: "<All Initiatives>" },
];
export const DEFAULT_ASSIGNEE_FILTER = [{ label: "<All Assignee>", value: "<All Assignee>" }];
export const DEFAULT_SPRINT_FILTER = [{ label: "<All Sprints>", value: "<All Sprints>" }];
//JIRA Fields:
export const JIRA_START_ITERATION = "customfield_12101";
export const JIRA_END_ITERATION = "customfield_12102";
export const JIRA_PLANNING_STATUS = "customfield_12000";
export const JIRA_PRODUKT_KATEGORI = "customfield_11200";
export const JIRA_EPIC_LINK_KEY = "customfield_10006";
export const JIRA_PLANNING_PRI = "customfield_12100";
export const JIRA_INITIATIVE = "customfield_12201";
export const JIRA_GUESTIMATE = "customfield_12200";
export const JIRA_SPRINT = "customfield_10005";

export const fields =
  "fields=key,summary,issuetype,assignee,status, labels, resolution, project" +
  "," +
  JIRA_START_ITERATION +
  "," +
  JIRA_END_ITERATION +
  "," +
  JIRA_PLANNING_PRI +
  "," +
  JIRA_PLANNING_STATUS +
  "," +
  JIRA_PRODUKT_KATEGORI +
  "," +
  JIRA_EPIC_LINK_KEY +
  "," +
  JIRA_INITIATIVE +
  "," +
  JIRA_GUESTIMATE + 
  "," + 
  JIRA_SPRINT;

//GROUP_BY
export const GROUP_BY_NO_GROUPING = 1;
export const GROUP_BY_ASSIGNEE = 2;
export const GROUP_BY_EPIC = 3;
export const GROUP_BY_INITIATIVE = 4;
export const GROUP_BY_PRODUCT = 5;
export const GROUP_BY_PLANNING_PRI = 6;
export const GROUP_BY_PROJECT = 7;

//prettier-ignore
export const GROUP_BY_SELECT_OPTION = [
  { value: GROUP_BY_NO_GROUPING, label: "No grouping",},
  { value: GROUP_BY_ASSIGNEE, label: "Assignee" },
  { value: GROUP_BY_EPIC, label: "Epic" },
  { value: GROUP_BY_INITIATIVE, label: "Initiative" },
  { value: GROUP_BY_PLANNING_PRI, label: "Planning Pri" },
  { value: GROUP_BY_PRODUCT, label: "Product" },
//TODO: Add back support for groupByProject
  //  { value: GROUP_BY_PROJECT, label: "Group by project" },
];


export const DEMO_DONE_LABEL = "Demo_Done";
export const DEMO_PLANNED_LABEL = "Iteration_Demo";
export const DEMO_NO = 1;
export const DEMO_PLANNED = 2;
export const DEMO_DONE = 3;
export const DEMO_SELECT_LIST = [
  { value: DEMO_NO, label: "No" },
  { value: DEMO_PLANNED, label: "Planned" },
  { value: DEMO_DONE, label: "Done" },
];
export function pickColorJira(status, planningStatus,resolution, dimColor = false) {
  if (dimColor){
    return COLOR_DIM;
  } else   if (status === "Resolved" || status === "Closed"|| status === "Done") {
    if (planningStatus === PLANNING_STATUS_NA) {
      return COLOR_DONE_NA;
    } else if (resolution ==="Duplicate" && status === "Resolved" ){
      return COLOR_DUPLICATE;
    } else if (resolution ==="Done" || resolution ==="Fixed" || resolution ==="Duplicate" || resolution ===""){
      return COLOR_DONE;
    } else  {
      return COLOR_REMOVED;
    }
  } else if (status === "QA" || status === "QA WIP") {
    if (planningStatus === PLANNING_STATUS_NA) {
      return COLOR_DONE_NA;
    } else {
      return COLOR_DONE_QA;
    }
  } else if (planningStatus === "FuncSpec Complete") {
    return COLOR_PLANNING;
  } else if (planningStatus === "FuncSpec in progress") {
    return COLOR_SPECING;
  } else if (planningStatus === "Not Started") {
    return COLOR_SPECING;
  } else if (planningStatus === "Ready4Coding") {
    return COLOR_READY4CODING;
  } else if (planningStatus === "DevPlan in progress") {
    return COLOR_PLANNING;
  } else if (planningStatus === PLANNING_STATUS_NA) {
    return COLOR_NA;
  } else if (planningStatus === "") {
    return COLOR_SPECING;
  }

  console.log(
    "Constant.js - ColorTest - status: " + status + " - planningStatus: " + planningStatus
  );
  return COLOR_UNKNOWN;
}

export function formatDate(date) {
  // prettier-ignore
  const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  if (date !== null) {
    return date.getDate() + ". " + months[date.getMonth()] + " " + date.getFullYear();
  } else return "";
}

export function addDaysToDate(date, numDays) {
  const _newDate = new Date(date);
  _newDate.setDate(_newDate.getDate() + numDays);

  return _newDate;
}



//**************************************************************************
// J2A migration
//**************************************************************************
export const J2A_ADO_SELECTED_PROJECT = "j2a_ado_selected_project";
export const J2A_JIRA_SELECTED_PROJECT = "j2a_jira_selected_project";
export const J2A_JIRA_PROJECTS=[{
    label: "UNI",
    value: "UNI"
  },
  {
    label: "IKOS",
    value: "IKOS"
  },
  {
  label: "APPS",
  value: "APPS"
  }
]

export const J2A_JIRA_QUERY = "https://jira.sensio.no/rest/api/2/search?jql=project+%3D+UNI+AND+Type+not+in+(Initiative)+AND+%22Start+iteration%22+is+not+EMPTY+AND+(status+not+in+(Resolved,+Closed)+OR+%22End+iteration%22+%3E%3D+-28d)+AND+%22Start+iteration%22+%3C%3D+120d+ORDER+BY+%22Planning+priority%22+ASC,+%22Start+iteration%22+ASC&maxResults=500";