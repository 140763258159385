import { handleResponse, handleError } from "./apiUtils";
import * as tConst from "../common/Constants";


function getHeaders(auth, ContentType = "application/json") {
  const auth_token = btoa(auth.un + ":" + auth.pw);

  const headers = {
      "Accept": "application/json",
      'Content-Type': ContentType,
      "Authorization": `Basic ${ auth_token }`,
  };
  return headers;
}


export function getProjects(auth) {

  const headers = getHeaders(auth);
  const url = tConst.AZURE_DEVOPS_API_URI+"projects";

  return fetch(url, { method: "GET", headers: headers }).then(handleResponse).catch(handleError);
}


export function getItemsList(auth, query, project = null) {

  
  const headers = getHeaders(auth);

  let url = ""
  if (project == null || project === "") {
    url = tConst.AZURE_DEVOPS_API_URI+"wit/wiql?api-version=7.2-preview.2";
  }
  else {
    url = tConst.AZURE_DEVOPS_BASE_URI+project+"/_apis/wit/wiql?api-version=7.2-preview.2"
  }

  console.log("query: "+JSON.stringify(query, null, 4));
  return fetch(url, { method: "POST", headers: headers, body: JSON.stringify(query)}).then(handleResponse).catch(handleError);
}

export function getItems(auth, idList) {

  if (idList.length > 0 ){

    //     const queryString = JSON.stringify({ids: [297,299,300],   fields: [
    const queryString = JSON.stringify({ids: idList,  fields: [
      tConst.AF_ID,
      tConst.AF_TITLE,
      tConst.AF_WORKITEM_TYPE,
      tConst.AF_STATE,
      "Microsoft.VSTS.Scheduling.RemainingWork",
      tConst.AF_PLANNING_STATUS,
      tConst.AF_PLANNING_PRIORITY,
      tConst.AF_START_ITERATION,
      tConst.AF_END_ITERATION,
      tConst.AF_INITIATIV,
      tConst.AF_JIRA_LINK, 
      tConst.AF_ASSIGNED_TO,
      tConst.AF_REPORTER,
      tConst.AF_PARENT,
      tConst.AF_PROJECT,
      tConst.AF_AREA_PATH,
      tConst.AF_REPRO_STEPS,
      tConst.AF_DESCRIPTION,
      tConst.AF_JUSTIFICATION,
      tConst.AF_TRIAGE_DATA,
      tConst.AF_TRIAGE_DECISION,
      tConst.AF_TAGS
    ]});

    
    console.log("query: "+JSON.stringify(queryString, null, 4));

    const headers = getHeaders(auth);
    
    const url = tConst.AZURE_DEVOPS_API_URI+"wit/workitemsbatch?$top=500&api-version=7.2-preview.1&$expand=relations";
    
    return fetch(url, { method: "POST", headers: headers, body: queryString}).then(handleResponse).catch(handleError);
  }
}

export function getItem(auth, id) {

  const isNotNum = isNaN(id);
  if (!isNotNum ){

    const headers = getHeaders(auth);
    
    const url = tConst.AZURE_DEVOPS_API_URI+"/wit/workitems/"+id+"?api-version=7.1-preview.3&$expand=relations"
    
    return fetch(url, { method: "GET", headers: headers}).then(handleResponse).catch(handleError);
  }
}

export function RemoveFromTimeline(auth, ticketId){

  const data = JSON.stringify([
    {
      "op": "remove",
      "path": "/fields/"+tConst.AF_START_ITERATION,
    },
    {
      "op": "remove",
      "path": "/fields/"+tConst.AF_END_ITERATION,
    },
  ]) 
  
  
  const headers = getHeaders(auth, "application/json-patch+json");
    
  const url = tConst.AZURE_DEVOPS_API_URI+"wit/workitems/"+ticketId+"?api-version=7.1-preview.3"
      
      return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);
  

}


export function onUpdateTicket(auth, ticketId, inputData){

  let dataObject = [];
  inputData.map((input) => {
    dataObject.push({op: "replace", path: input.attribute, value: input.value})
  })

  const data = JSON.stringify(dataObject);
  const headers = getHeaders(auth, "application/json-patch+json");
    
  const url = tConst.AZURE_DEVOPS_API_URI+"wit/workitems/"+ticketId+"?api-version=7.1-preview.3"
      
      return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);

}

export function setIteration(auth,ticketId, startDate, endDate){
  const data = JSON.stringify([
    {
      "op": "replace",
      "path": "/fields/"+tConst.AF_START_ITERATION,
      "value": startDate
    },
    {
      "op": "replace",
      "path": "/fields/"+tConst.AF_END_ITERATION,
      "value": endDate
    },
  ])  

  const headers = getHeaders(auth, "application/json-patch+json");
    
  const url = tConst.AZURE_DEVOPS_API_URI+"wit/workitems/"+ticketId+"?api-version=7.1-preview.3"
      
      return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);
  
}


export function moveAndExtendTicketIteration(auth,selectedTicket, moveNumberOfDays, extendNumberOfDays){

  const _start = new Date(selectedTicket.fields[tConst.AF_START_ITERATION]);
  _start.setDate(_start.getDate() + moveNumberOfDays)
  const _end = new Date(selectedTicket?.fields[tConst.AF_END_ITERATION]);
  _end.setDate(_end.getDate() + moveNumberOfDays+extendNumberOfDays)
  
  const data = JSON.stringify([
    {
      "op": "replace",
      "path": "/fields/"+tConst.AF_START_ITERATION,
      "value": _start
    },
    {
      "op": "replace",
      "path": "/fields/"+tConst.AF_END_ITERATION,
      "value": _end
    },
  ])  
  const headers = getHeaders(auth, "application/json-patch+json");
    
  const url = tConst.AZURE_DEVOPS_API_URI+"wit/workitems/"+selectedTicket.id+"?api-version=7.1-preview.3"
      
      return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);
  
}

export function addComment(auth, project, ticketId, comment){
  debugger;
  const headers = getHeaders(auth);

  
  const url = tConst.AZURE_DEVOPS_BASE_URI+project+"/_apis/wit/workItems/"+ticketId+"/comments?api-version=7.1-preview.3"    
  return fetch(url, { method: "POST", headers: headers, body: comment}).then(handleResponse).catch(handleError);
}
  
export function updateTicket(auth, ticketId, data){
  const headers = getHeaders(auth, "application/json-patch+json");
  
  const url = tConst.AZURE_DEVOPS_API_URI+"wit/workitems/"+ticketId+"?api-version=7.1-preview.3"    
  return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);
    
}

export function createLink(auth, fromTicketID, toTicketID, LinkType){
  const headers = getHeaders(auth, "application/json-patch+json");
 
  
  if (tConst.ADO_VALID_LINK_TYPES.indexOf(LinkType) === -1){
    throw new Error("Invalid LinkType: "+LinkType);
  }

const data = JSON.stringify( 
  [
    {
            "op": "add",
            "path": "/relations/-",
            "value": {
                "rel": "System.LinkTypes."+LinkType,
                "url": "https://dev.azure.com/sensio/_apis/wit/workItems/"+toTicketID
            }
        }
    ]);

  const url = tConst.AZURE_DEVOPS_BASE_URI+"/_apis/wit/workitems/"+fromTicketID+"?api-version=7.1-preview.3";

  return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);
}


export function createTicket(auth, project, ticketType, data){
  const headers = getHeaders(auth, "application/json-patch+json");
    
  const url = tConst.AZURE_DEVOPS_BASE_URI+project+"/_apis/wit/workitems/$"+ticketType+"?api-version=7.1-preview.3"

  return fetch(url, { method: "PATCH", headers: headers, body: data}).then(handleResponse).catch(handleError);
}

export async function  getChildrenList(auth, id, inclduePredecessors = true, includeChildren = true)
{
  let _children = [];
  
  const response = await getItem(auth, id);
  if (response.relations) {

    response.relations.map((relation) => {
      if (includeChildren && relation.attributes.name ==="Child"){
        const child = relation.url.split("/");
        child.type = "Child";
        _children.push(child[child.length-1])
      }
      else if (inclduePredecessors && relation.attributes.name ==="Predecessor"){
        debugger;
        const Predecessor = relation.url.split("/");
        Predecessor.type = "Predecessor";
        _children.push(Predecessor[Predecessor.length-1])
      }
      else {
        debugger;
        console.log("Relation that was not children" + JSON.stringify(relation, null, 4))
      }
    })
  }
  
   return _children;
  
}


function getChildren(auth, id){
  let _children = [];

  getItem(auth, id).then((response)=>{
    response.relations.map((relation) => {
      if (relation.attributes.name ==="Child"){
        const child = relation.url.split("/");
        _children.push(child[child.length-1])
      }
    })
    return _children
  })
}


