import React, { useState } from "react";
//import { Button } from "react-bootstrap"
import { Button, Divider,InputNumber, Tooltip } from 'antd';
import { ApartmentOutlined, CheckOutlined, FormOutlined, DeleteRowOutlined, SendOutlined, FireOutlined } from '@ant-design/icons';

import * as AzureService from "../../api/ticketsApiAzure";
import * as tConst from "../../common/Constants";
import * as date from "../../utils/date";

const ChartMenuToolbar = ({selectedTicket, onClose, auth, onMoveAndExtendIteration }) => {
    const [move, setMove] = useState(0);
    const [length, setLength] = useState(selectedTicket.Length);
    const [includeTeamDependencies, setIncludeTeamDependencies] = useState(false);
    const [linkToTicketID, setLinkToTicketID] = useState("0");
    const [showCreateLink, setShowCreateLink] = useState(false);

    const dis = (["Epic", "Feature", "Initiative"].indexOf(selectedTicket.fields[tConst.AF_TYPE]) > -1);
    const unchangedPeriod = (move === 0 && length === selectedTicket.Length);
    const _show_hide_text = (includeTeamDependencies) ? "Hide Team Dependencies" : "Show Team Dependencies";
    return(
        <div style={{ backgroundColor: '#F1F2F3', padding: '10px', borderRadius: '5px' }}>
            <Tooltip title="Add Planning Tasks">
            <Button 
                color="primary" variant="solid"
                size="sm"
                icon={<FormOutlined />}
                disabled={!dis} 
                onClick={()=>{
                    tConst.AZURE_SPECS.map((spec) => {
                        return AddPlanningTasks(auth, selectedTicket, spec);
                    })
                    onClose();
                        
                }}
            /> &nbsp;
            </Tooltip>

            <Tooltip title="Remove from Timeline">
                <Button
                    color="primary" variant="solid"
                    size="sm"
                    icon={<DeleteRowOutlined />}
                    onClick={()=>{
                    AzureService.RemoveFromTimeline(auth, selectedTicket.id)
                    onClose();

                }}/>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Create Initiative in Team projects">
                <Button disabled color="primary"  icon={<SendOutlined/>} variant="solid" size="sm" onClick={()=>{console.log("TBD")}}/>
            </Tooltip>

            <Tooltip title={showCreateLink ? "Hide Link " : "Link Predecessor to existing ticket"}>
                <Button 
                    color="primary"  
                    icon={<FireOutlined/>} 
                    variant={showCreateLink ? "outline" : "solid"} 
                    size="sm" 
                    onClick={()=>{
                        setShowCreateLink(!showCreateLink);
                    }}
                />
            </Tooltip>


            <Tooltip title={_show_hide_text}>
                <Button  
                    color="primary"  
                    icon={<ApartmentOutlined />} 
                    variant={(includeTeamDependencies? "outlined" :"solid")} 
                    size="sm" 
                    onClick={()=>{
                        setIncludeTeamDependencies(!includeTeamDependencies);
                    }}
                />
            </Tooltip>

            <Divider type="vertical" />

            <label>Move: </label>
            <InputNumber min={-7} max={7} value={move} onChange={(value)=>{setMove(value);}}/> &nbsp;&nbsp;

            <label>Length: </label>
            <InputNumber min={1} max={12} value={length} onChange={(value) => {setLength(value)}}/> &nbsp;&nbsp;

                
            <Tooltip title="Update period and lenght">
                <Button color="primary" icon={<CheckOutlined />} variant="solid" size="sm" onClick={()=>{
                    if (move !== 0 || length !== selectedTicket.Length) {
                        debugger;
                        onMoveAndExtendIteration(selectedTicket, move * 14, (length-selectedTicket.Length)*14);
                    }
                    console.log("Move: ", move, "Length: ", (length-selectedTicket.Length)*14);
                }} disabled={unchangedPeriod}/>
            </Tooltip>
           <br/>

           {showCreateLink && <>
            <label>Link to Ticket ID: </label> &nbsp;
            <InputNumber min={0} value={linkToTicketID} onChange={(value) => {setLinkToTicketID(value)}}/>
            <Tooltip title="Link">
                <Button 
                    // disabled={(linkToTicketID !== 0 && linkToTicketID !== selectedTicket.id)}
                    color="primary" 
                    icon={<CheckOutlined />} 
                    variant="solid" 
                    size="sm" 
                    onClick={()=>{
                        debugger;
                        if (linkToTicketID !== 0 && linkToTicketID !== selectedTicket.id) {
                            AzureService.createLink(auth, selectedTicket.id, linkToTicketID, "Dependency-Reverse").then((response) => {
                                console.log("Link created: ", response);
                            });
                        }
                    }} 
                    />
            </Tooltip>
            </>}

           

        </div>
    )

}


const AddPlanningTasks = (auth, selectedTicket, spec) => {
    const initiative = selectedTicket.fields[tConst.AF_INITIATIV];
    const _project = selectedTicket.fields[tConst.AF_PROJECT];
    const _id = selectedTicket.id;
    const _title = spec + ": " +selectedTicket.fields[tConst.AF_TITLE];
    const PlanningPriority = selectedTicket.fields[tConst.AF_PLANNING_PRIORITY];
    const _PlanningPriority = 0.1 + Number(PlanningPriority)
    const _end = new Date(selectedTicket?.fields[tConst.AF_END_ITERATION]);
    const _start = new Date(selectedTicket?.fields[tConst.AF_START_ITERATION]);
    //Todo - implement check when reducing this to the same day or less
    const _dateDiff = date.date_diff_indays(_start, _end);

    const _FuncSpec =  tConst.AZURE_SPECS[0];


    if (spec === _FuncSpec) {
        _end.setDate(_end.getDate() - (14 + _dateDiff));
        _start.setDate(_start.getDate() - 28 )
    
    } else {
        _end.setDate(_end.getDate() - (_dateDiff));
        _start.setDate(_start.getDate() - 14 )
    } 

    const data = JSON.stringify([
        {
            "op": "add",
            "path": "/fields/System.Title",
            "from": null,
            "value": _title},
           {
            "op": "add",
            "path": "/relations/-",
            "value": {
              "rel": tConst.AF_REL_PARENT,

              "url": tConst.AZURE_DEVOPS_BASE_URI+_project+"/_apis/wit/workItems/"+_id
            }
        },
        {
            "op": "add",
            "path": "/fields/"+tConst.AF_INITIATIV,
            "value": initiative
        },
        {
            "op": "add",
            "path": "/fields/"+tConst.AF_PLANNING_PRIORITY,
            "value": _PlanningPriority
        },
        {
            "op": "add",
            "path": "/fields/"+tConst.AF_START_ITERATION,
            "value": _start
          },
          {
            "op": "add",
            "path": "/fields/"+tConst.AF_END_ITERATION,
            "value": _end
          },
          {
            "op": "add",
            "path": "/fields/"+tConst.AF_PLANNING_STATUS,
            "value": "9. N/A"
          }      


    ])  
    AzureService.createTicket(auth,_project, "product backlog item", data)

}

export default ChartMenuToolbar