import React from 'react';
import { Select } from 'antd';



const TriageToolbar = ({ projects, selectedProject, projectOnChange }) => {

    return (
        <div className="triage-toolbar" style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', backgroundColor: 'lightblue' }}>
            <div>
                <label htmlFor="project-select"><b>Project:</b></label>
            </div>
            <Select
                id="project-select"
                placeholder="Select a project"
                style={{ width: 200 }}
                value={selectedProject}
                onChange={projectOnChange}
            >
                {projects.map(project => (
                    <Select.Option key={project.id} value={project.id}>
                        {project.name}
                    </Select.Option>
                ))}
            </Select>
            {/*selectedProject*/}
        </div>
    );
};


export default TriageToolbar;